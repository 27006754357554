import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Hollow Rock/Floor L Sit variations`}</p>
    <p>{`:10 Cross Leg Lift Hold`}</p>
    <p>{`:20 Rest`}</p>
    <p>{`:10 Seated Pike Compressions`}</p>
    <p>{`:20 Rest`}</p>
    <p>{`:10 Hollow Rock`}</p>
    <p>{`:20 Rest`}</p>
    <p>{`:10 Superman Hold`}</p>
    <p>{`:20 Rest`}</p>
    <p>{`:10 Bird dog Hold, Right`}</p>
    <p>{`:20 Rest`}</p>
    <p>{`:10 BIrd dog Hold, Left`}</p>
    <p>{`x 4 Rounds`}</p>
    <p>{`then,`}</p>
    <p>{`Partner WOD`}</p>
    <p>{`50 HSPU’s`}</p>
    <p>{`100 Calorie Row`}</p>
    <p>{`50 Partner Burpees`}</p>
    <p>{`100 Calorie BIke`}</p>
    <p>{`50 Ring Rows`}</p>
    <p>{`100 KBS (53/35)`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Pop Up Mobility classes with Glenn will start next Wednesday,
March 6th, at 11:30am and Sunday, March 10th, at 12:00am.  This is free
for all Unlimited members, \\$10 for anyone else, so pop in if you can
make it!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      